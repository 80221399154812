import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: "",
    title: "Futurehive Smart Solutions (Collins)",
    description:
      "Exceptional service and support throughout the entire process. Initially, there were some challenges in aligning our specific requirements with the implementation plan, but the team handled these issues promptly and professionally. Their willingness to listen to feedback, make adjustments, and go the extra mile ensured that we ended up with a result that exceeded our expectations. Despite the initial hiccups, the final outcome was polished, efficient, and delivered on time. Highly recommended for anyone looking for quality and a team that truly values collaboration and customer satisfaction.",
  },
  {
    id: 2,
    image: "",
    title: "Intelliestate Ventures (Doris)",
    description:
      "A seamless experience with outstanding results. The attention to detail and professionalism were top-notch!",
  },
  {
    id: 3,
    image: "",
    title: "Chama Tajiri (Esther)",
    description:
      "Truly impressed with the dedication and commitment to delivering exactly what was promised. A fantastic experience!",
  },
];
