import Work1 from "../../assets/work1.png";
import Work2 from "../../assets/work2.png";
import Work3 from "../../assets/work3.png";
import Work4 from "../../assets/work4.png";
import Work5 from "../../assets/work5.png";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Futurehive",
    category: "web",
    link: "https://futurehivesmartsolutions.com/",
  },
  {
    id: 2,
    image: Work2,
    title: "Intelliestate Ventures",
    category: "web",
    link: "https://intelliestate.mwendwa.xyz/",
  },
  {
    id: 3,
    image: Work3,
    title: "Comicare API",
    category: "api",
    link: "https://comicare.mwendwa.xyz/docs",
  },
  {
    id: 4,
    image: Work4,
    title: "Bodawatchdog API",
    category: "api",
    link: "https://bodawatchdog.mwendwa.xyz/docs",
  },
  {
    id: 5,
    image: Work5,
    title: "Chama Tajiri",
    category: "app",
    link: "#",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "api",
  },
  {
    name: "app",
  },
];
